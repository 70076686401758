import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../dataContext";
import DoubleIcon from "../components/DoubleIcon";
import TransferDetails from "../components/TransferDetails";
import SendSms, { isMacOs, clickSendSms } from "../components/SendSMS";
import { ReactComponent as Check } from "../assets/ico_check.svg";
import { ReactComponent as Info } from "../assets/ico_info.svg";
import { ReactComponent as Copy } from "../assets/ico_copy.svg";
import BottomButton from "../components/BottomButton";

const TransferSuccessful = ({ branding, homePath }) => {
  const navigate = useNavigate();
  const { history, notificationFeed } = useContext(DataContext);
  const urlParams = useParams();
  const [resendData, setResendData] = useState({});

  const transfer = history.find(({ id }) => id === urlParams.id);
  const notification = notificationFeed.find(
    ({ transfer_id }) => transfer_id === urlParams.id
  );

  // notification/feed doesn't work locally, uncomment an option below

  // // ON NETWORK
  // const notification = notificationFeed.find(
  //   ({ transfer_id }) => transfer_id === "17bfb8a4-2fa3-5615-b4b4-e3cc6151c65d"
  // );

  // const transfer = {
  //   id: urlParams.id,
  //   on_network: true,
  //   status: notification?.status?.toUpperCase(),
  // };

  // // OFF NETWORK
  // const notification = notificationFeed.find(
  //   ({ transfer_id }) => transfer_id === "c35bc840-1865-5bf5-916d-b85ce20589c1"
  // );

  // const transfer = {
  //   amount: notification?.amount,
  //   id: urlParams.id,
  //   note: "Dummy data OFF network succeeded",
  //   on_network: false,
  //   // recipient: { type: "phone", value: "+16155551234" },
  //   recipient: { type: "email", value: "dummy@test.com" },
  //   sender_name: "Chet Wallsworth",
  //   status: "PENDING",
  //   timestamp: "2024-05-21T15:48:54.181114",
  // };

  const shouldShowSmsSend = () => {
    if (transfer !== undefined) {
      return (
        transfer.recipient?.type === "phone" &&
        isMacOs &&
        (notification?.shortlink ||
          (notification?.status === "succeeded" &&
            branding?.send_on_network_notification))
      );
    }
    return false;
  };

  useEffect(() => {
    if (notification?.shortlink) {
      setResendData({
        identifier: transfer?.recipient?.value,
        children: "",
        disabled: false,
        message: `${branding.sms_customization.off_net_first} $${transfer?.amount?.value} ${branding.sms_customization.off_net_second} ${notification?.financial_institution} ${branding.sms_customization.off_net_third} "${transfer?.note}". ${branding.sms_customization.off_net_fourth} ${notification?.shortlink}`,
      });
    } else if (notification?.status === "succeeded") {
      setResendData({
        identifier: transfer?.recipient?.value,
        children: "",
        disabled: false,
        message: `${branding.sms_customization.on_net_first} $${transfer?.amount?.value} ${branding.sms_customization.on_net_second} "${transfer?.note}". ${branding.sms_customization.on_net_third}`,
      });
    }
  }, [branding, notification, transfer]);

  useEffect(() => {
    if (shouldShowSmsSend()) {
      if (notification?.shortlink) {
        clickSendSms(
          `${branding.sms_customization.off_net_first} $${transfer?.amount?.value} ${branding.sms_customization.off_net_second} ${notification?.financial_institution} ${branding.sms_customization.off_net_third} "${transfer?.note}". ${branding.sms_customization.off_net_fourth} ${notification?.shortlink}`,
          transfer?.recipient?.value
        );
      } else if (branding?.send_on_network_notification) {
        clickSendSms(
          `${branding.sms_customization.on_net_first} $${transfer?.amount?.value} ${branding.sms_customization.on_net_second} "${transfer?.note}". ${branding.sms_customization.on_net_third}`,
          transfer?.recipient?.value
        );
      } else {
        console.warn("sms data issue");
      }
    }
  });

  const clickCopy = () => {
    navigator.clipboard.writeText(resendData.message);
    document.getElementById("show_click_copy").style.display = "block";
    setTimeout(() => {
      document.getElementById("show_click_copy").style.display = "none";
    }, 1000);
  };

  const smallSuccessIcon = () => {
    return (
      <Check
        style={{
          height: "auto",
          width: "40px",
          margin: "-35px 0 0 60px",
          zIndex: 1,
          borderRadius: "20px",
        }}
        className="fill_sending_success_color stroke_sending_bg_primary bg_sending_bg_primary"
      />
    );
  };

  const { renderDoubleIcon } = DoubleIcon(smallSuccessIcon);

  const nonDetailScreen = () => {
    return (
      <div
        className="center_page"
        style={{
          height: "65%",
          flexDirection: "column",
        }}
      >
        <h1>Success</h1>
        <div
          data-cy="transfer_processed_message"
          style={{ margin: "-15px 0 25px 0", fontSize: "16px" }}
        >
          Payment was sent
        </div>
        {renderDoubleIcon()}
      </div>
    );
  };

  const detailScreen = () => {
    return (
      <div>
        <h1
          data-cy="transfer_processed_message"
          style={{ margin: 0, lineHeight: 1.3 }}
        >
          {transfer.on_network ? (
            <> Your transaction has been sent!</>
          ) : (
            <> Your transaction is ready to be sent!</>
          )}
        </h1>
        <div className="transfer_action_info" style={{ marginBottom: "15px" }}>
          <div style={{ marginRight: "10px" }}>
            <Info className="fill_sending_bg_primary stroke_sending_bg_secondary" />
          </div>
          {transfer.recipient?.type === "phone" ? (
            <>
              Please text ("SMS") the instructions below to the recipient to
              claim funds...
            </>
          ) : (
            <>Instructions have been sent to recipient's email</>
          )}
        </div>
        <h3 style={{ marginTop: "20px" }}>Instructions for your recipient</h3>
        <div
          className="status"
          style={{
            marginBottom: "15px",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16px",
          }}
        >
          <span>
            {notification?.shortlink ? (
              <>
                {resendData.message?.split("https")[0]}
                <strong style={{ textDecoration: "underline" }}>
                  https{resendData.message?.split("https")[1]}
                </strong>
              </>
            ) : (
              <>{resendData.message}</>
            )}
          </span>
          <div style={{ textAlign: "right" }}>
            <Copy
              className="fill_sending_bg_tertiary stroke_sending_bg_secondary"
              onClick={() => clickCopy()}
              style={{ cursor: "pointer" }}
            />
            <div id="show_click_copy" style={{ display: "none" }}>
              Message copied
            </div>
          </div>
        </div>
        {shouldShowSmsSend() && <SendSms {...resendData}></SendSms>}
        <div style={{ marginTop: "40px" }}>{TransferDetails(transfer)}</div>
      </div>
    );
  };

  return (
    <main className="page_without_nav">
      {transfer?.status ? (
        <>
          {transfer.on_network && !branding.send_on_network_notification ? (
            nonDetailScreen()
          ) : (
            <div
              style={{
                paddingBottom: "20px",
              }}
            >
              {detailScreen()}
            </div>
          )}
        </>
      ) : (
        <div className="center_page">Transfer Not Found</div>
      )}
      {BottomButton(() => navigate(homePath), "Return Home")}
    </main>
  );
};

export default TransferSuccessful;
